/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
  border: 1px solid #4d4545;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  border : 1px solid #685f5f;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8085a0;
  border-radius: 5px;
  width: 4px;
  border: 3px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #505052;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-logo-background-image {
  width: 80px;
  height: 80px;
  background-image: url("./logo.svg");
  background-size: 100% 100%;
}

.home1-screen-background {
  background-image: url("../public/home1.PNG");
  background-size: 100% 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
